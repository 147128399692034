import React from "react";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";

const menuItems = [
  { icon: "👤", label: "Your profile" },
  { icon: "🔐", label: "Account details" },
  { icon: "🎨", label: "Artists you follow" },
  { icon: "🏢", label: "Businesses you follow" },
  { icon: "🛍️", label: "My purchases" },
  { icon: "💰", label: "My sales" },
  { icon: "📊", label: "Insights" },
  { icon: "💎", label: "Object royalties" },
  { icon: "🏦", label: "Insurance manager" },
  { icon: "📞", label: "Contact Asign Care" },
  { icon: "🚪", label: "Log out" },
];

const Sidebar = ({ children }: { children: React.ReactNode }) => {
  return (
    <Sheet>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="w-[300px] sm:w-[350px] p-0">
        <SheetHeader className="p-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <SheetTitle className="text-xl font-semibold">You</SheetTitle>
          </div>
        </SheetHeader>
        <div className="py-1">
          {menuItems.map((item, index) => (
            <Button
              key={index}
              variant="ghost"
              className="justify-start w-full px-4 py-3 text-base font-normal hover:bg-gray-100"
            >
              <span className="mr-4 text-lg">{item.icon}</span>
              {item.label}
              <ChevronRight className="w-5 h-5 ml-auto text-gray-400" />
            </Button>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default Sidebar;
