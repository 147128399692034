import artist from "@/apis/artist";
import Header from "@/components/Global/header/Header";
import { MobileHeader } from "@/components/Global/header/MobileHeader";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import React, { useEffect, useRef, useState } from "react";
import useObjects from "@/apis/objects";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { formatInRuppes } from "@/lib/helper";
import { LikeObject } from "@/components/Templates/Objects/OBig";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { ChevronDown } from "lucide-react";
const sideBar = [
  { label: "Sold by" },
  { label: "Object essay" },
  { label: "Details" },
  // { label: "Provence" },
  { label: "About the artist" },
  { label: "More by the artist" },
];
const ObjectDetail = () => {
  const [currentSection, setCurrentSection] = useState<string>("");
  const { getObjectById } = useObjects();
  const { id } = useParams() as { id: string };
  const { data: object } = useQuery({
    queryKey: ["object", id],
    queryFn: () => getObjectById({ id }),
    enabled: Boolean(id),
  });
  const refs = sideBar.reduce((acc, value) => {
    acc[value.label] = useRef<HTMLDivElement>(null);
    return acc;
  }, {} as { [key: string]: React.RefObject<HTMLDivElement> });

  const scrollToSection = (label: string) => {
    const ref = refs[label]?.current;

    if (ref) {
      setCurrentSection(label);
      ref.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "-10px",
      threshold: 0.7,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.getAttribute("data-section") ?? "");
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    Object.values(refs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [refs]);
  return (
    <>
      <Header />
      <div className="container font-normal font-sh5">
        <div className="fixed z-10 w-full top-15 mb-15">
          <MobileHeader
            data={sideBar}
            active={currentSection}
            onClick={scrollToSection}
          />
        </div>
        <div className="px-5 pt-16 lg:bg-transparent bg-[#F6F6F6] md:p-5">
          <Breadcrumb className="hidden lg:flex">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Discover</BreadcrumbLink>
              </BreadcrumbItem>
              <span className="text-black">/</span>
              <BreadcrumbItem>
                <BreadcrumbLink href="/objects/feed">objects</BreadcrumbLink>
              </BreadcrumbItem>
              <span className="text-black">/</span>
              <BreadcrumbItem>
                <BreadcrumbPage>{object?.objectName}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          {/* cover image and the object information */}
          <div className="items-center hidden gap-10 lg:flex">
            {/* object information */}
            <div className="w-1/3">
              {object?.assignProtect && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <p className="flex items-center gap-1 font-medium underline">
                        <img
                          src="/protect.svg"
                          alt="asign protect"
                          className="w-[24px] h-[24px]"
                        />
                        Asign Protect+
                      </p>
                    </TooltipTrigger>
                    <TooltipContent className="w-full p-1  px-5 text-sm  bg-[#F2F2F2] rounded-md max-w-[300px] truncate left-0 ">
                      <p>
                        Asign Object Number:{" "}
                        <span className="font-medium">AAW12345</span>
                      </p>
                      <p>
                        Labelled on:{" "}
                        <span className="font-medium">Jun 23, 2023</span>
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              <p className="text-5xl capitalize font-eb text-gray-60">
                {object?.objectName}
              </p>

              <p className="flex items-center justify-between">
                <span className="text-xl text-gray-60">
                  Moon (Quiet Yellow Water), 1992
                </span>
                <LikeObject id={object?.id} className="bg-transparent" />
              </p>
              <p className="mt-3 text-gray-600 line-clamp-2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est
                numquam, distinctio dolorum dolore deserunt commodi assumenda,
                sint quis, repellat quod molestiae. Est, vero! Magni quasi earum
                nesciunt. Dignissimos, aliquam perspiciatis!
              </p>
              <p className="mt-1 text-gray-60">24 cm x 24 cm (12 in x 12 in)</p>

              {/* object price */}
              <h4 className="mt-10 text-2xl font-medium">
                {" "}
                {formatInRuppes.format(object?.price as number)}
              </h4>
              <p className="text-gray-60">
                {" "}
                Total price: {formatInRuppes.format(object?.price as number)}
              </p>
              <p className="mt-3 font-medium underline uppercase">
                View price break up
              </p>

              <div className="flex gap-5 mt-10">
                <Button className="font-medium rounded-full">ADD TO BAG</Button>
                <Button variant="outline" className="font-medium rounded-full">
                  HOLD
                </Button>
              </div>
              <p className="mt-4 underline ">MAKE AN OFFER</p>
            </div>
            {/* object cover image */}
            <div className="w-2/3 ">
              <img
                src={object?.image}
                alt=""
                className="w-full h-full max-h-[500px] object-contain"
              />
            </div>
          </div>

          {/* mobile cover image and the object info */}
          <div className="block lg:hidden">
            <div className="w-full">
              <img
                src={object?.image}
                alt=""
                className="w-full h-full max-h-[500px] object-cover"
              />
              <p className="mt-1 text-center text-gray-60 ">
                24 cm x 24 cm (12 in x 12 in)
              </p>
            </div>
            <div className="mb-1 mt-[30px] pb-5">
              {object?.assignProtect && (
                <p className="flex items-center gap-1 text-sm ">
                  <img src="/protect.svg" alt="asign protect" />
                  Asign Protect+
                </p>
              )}
              <p className="text-5xl capitalize font-[400] font-eb mt-1 truncate text-[#4F4F4F]">
                {object?.objectName}
              </p>
              <p className="flex items-center justify-between">
                <span className="text-lg font-normal truncate text-gray-60">
                  Moon (Quiet Yellow Water), 1992
                </span>
                <LikeObject id={object?.id} />
              </p>
              <h4 className="my-6 text-[24px] font-normal">
                {" "}
                {formatInRuppes.format(object?.price as number)}
              </h4>
              <Button className="w-full font-medium rounded-full">
                ADD TO BAG
              </Button>
              <Button
                variant="outline"
                className="w-full my-5 font-medium rounded-full"
              >
                HOLD
              </Button>
              <div className="grid place-items-center">
                {" "}
                <Button
                  variant="link"
                  className="h-auto p-0 text-sm font-medium underline "
                >
                  MAKE AN OFFER
                </Button>
                <div className="flex items-center justify-between mt-[21px] mb-10 text-sm gap-x-1">
                  <span>MORE ACTIONS</span>
                  <ChevronDown className="w-4 h-4" />
                </div>
              </div>
              <Separator className="mb-4" />
              <div className="flex items-center justify-between text-sm gap-x-1">
                <span>Shipping and taxes</span>
                <ChevronDown className="w-4 h-4" />
              </div>
              <Separator className="mt-4" />
            </div>
          </div>
        </div>

        {/* ******************* */}
        <div className="relative flex p-5 mt-5 lg:px-[48px]">
          <div className="hidden lg:w-1/3 md:block">
            <div className="overflow-y-scroll text-gray-60">
              {sideBar.map((item) => (
                <button
                  key={item.label}
                  tabIndex={0}
                  className={cn(
                    "mb-[20px] cursor-pointer whitespace-nowrap hover:text-gray-100 w-full text-left ",
                    currentSection === item.label && "text-gray-100"
                  )}
                  onClick={() => scrollToSection(item.label)}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
          {/* ********** */}
          <div className="lg:w-2/3 lg:max-h-[500px] overflow-y-scroll">
            <div ref={refs["Sold by"]} data-section="Sold by">
              <h3 className="font-normal text-[24px] mb-6">Sold By</h3>
              <div className="flex items-center gap-5">
                <Avatar>
                  <AvatarImage src="https://picsum.photos/200/300" />
                  <AvatarFallback>No</AvatarFallback>
                </Avatar>
                <div>
                  <p>Ansh Mourya</p>
                  <p className="text-xs text-gray-60">ne location</p>
                </div>
              </div>
            </div>
            <Separator className="my-[42px]" />
            <div ref={refs["Object essay"]} data-section="Object essay">
              <h3 className="font-normal text-[24px]">Object essay</h3>
              <p className="my-5">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Nesciunt illum, nisi numquam ipsa veniam culpa odit rerum eos
                consequatur ullam fugit dolorum debitis maiores itaque voluptas
                beatae autem, quos nobis. Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Eveniet fugiat quis quia error, quasi est
                sequi possimus magni impedit corrupti, exercitationem labore
                nostrum molestiae sed aperiam cupiditate quas consequuntur vel!
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Excepturi consectetur quos libero quis ipsum, ea sit possimus
                recusandae dolores quibusdam! Assumenda, inventore? Accusantium
                velit dignissimos perspiciatis perferendis placeat delectus
                minima?
              </p>
              <Button variant="outline" className="font-medium rounded-full">
                READ MORE
              </Button>
            </div>

            {/* ********** */}
            <Separator className="my-[42px]" />
            <div ref={refs["Details"]} data-section="Details">
              <h3 className="font-normal text-[24px]">Details</h3>

              <div className="grid grid-cols-1 my-5 md:grid-cols-2 lg:grid-cols-3 gap-y-5">
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <div key={item}>
                    <p className="text-sm text-gray-60">Artwork title</p>
                    <p className="">Moon (Quiet Yellow Water)</p>
                  </div>
                ))}
              </div>
            </div>

            {/* ******* */}
            <Separator className="my-[42px]" />
            {/* <div ref={refs["Provence"]} data-section="Details">
              <h3 className="font-medium text-[24px]">Provence</h3>

              <div className="grid grid-cols-1 my-5 md:grid-cols-2 lg:grid-cols-3 gap-y-5 ">
                <Tabs defaultValue="Overview" className="w-[400px]">
                  <div className="relative">
                    <div className=" mq750:block  mq450:block absolute h-full right-0 w-[20vw] pointer-events-none flex justify-end items-center bg-gradient-to-l from-[#F6F6F5] to-white">
                      <img
                        src="/arrow-right.svg"
                        className="mr-10"
                        alt="Scroll Right"
                      />
                    </div>

                    <TabsList className="mq450:bg-gradient-to-l mq450:w-[80vw] mq450:flex mq450:justify-start from-white to-transparent  mq450:overflow-x-auto">
                      <TabsTrigger
                        className="mq450:text-base bg-transparent pb-4 font-sh5 text-lg font-normal data-[state=active]:text-gray-100 data-[state=inactive]:text-gray-60"
                        value="Overview"
                      >
                        Overview
                      </TabsTrigger>
                      <TabsTrigger
                        className="mq450:text-base bg-transparent pb-4 font-sh5 text-lg font-normal data-[state=active]:text-gray-100 data-[state=inactive]:text-gray-60"
                        value="Auction"
                      >
                        Auction
                      </TabsTrigger>
                      <TabsTrigger
                        className="mq450:text-base bg-transparent pb-4 font-sh5 text-lg font-normal data-[state=active]:text-gray-100 data-[state=inactive]:text-gray-60"
                        value="Exhibitions"
                      >
                        Exhibitions
                      </TabsTrigger>
                      <TabsTrigger
                        className="mq450:text-base bg-transparent pb-4 font-sh5 text-lg font-normal data-[state=active]:text-gray-100 data-[state=inactive]:text-gray-60"
                        value="Publications"
                      >
                        Publications
                      </TabsTrigger>
                    </TabsList>
                  </div>
                  <div className="pb-10"></div>
                  <TabsContent value="Exhibitions">
                    <div className="flex flex-col gap-6 text-lg text-gray-100 font-sh5 mq450:text-base">
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                    </div>
                  </TabsContent>
                  <TabsContent value="Publications">
                    <div className="flex flex-col gap-6 text-lg text-gray-100 font-sh5 mq450:text-base">
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                    </div>
                  </TabsContent>
                  <TabsContent value="Auction">
                    <div className="flex flex-col gap-6 text-lg text-gray-100 font-sh5 mq450:text-base">
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                    </div>
                  </TabsContent>
                  <TabsContent value="Overview">
                    <div className="flex flex-col gap-6 text-lg text-gray-100 font-sh5 mq450:text-base">
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                      <p>Galerie Spiess, Paris, 2006</p>
                    </div>
                  </TabsContent>
                </Tabs>
              </div>
            </div> */}
            <div ref={refs["About the artist"]} data-section="About the artist">
              <h3 className="font-normal text-[24px] mb-6">About the artist</h3>
              <div className="flex items-center gap-5">
                <Avatar>
                  <AvatarImage src="https://picsum.photos/200/300" />
                  <AvatarFallback>No</AvatarFallback>
                </Avatar>
                <div>
                  <p>Ansh Mourya</p>
                  <p className="text-sm text-gray-60">ne location</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ObjectDetail;
