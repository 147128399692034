import React, { ReactNode, useCallback, useState } from "react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery, debounce } from "@mui/material";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useBoard from "@/apis/board";
import { AddItemResponse, GetAllBoardResponse } from "@/types/boards.type";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import useFilter, { AddItemParams, DeleteItemParam } from "@/hooks/useFilter";
import { Button } from "../ui/button";
import toast from "react-hot-toast";
import NewBoard from "@/modal/NewBoard";
import RemoveItem from "@/modal/RemoveItem";

type AddItemDropDownProps = {
  children: ReactNode;
  itemID: string;
  boardID: string;
  itemType: string;
};

const imageSources = [
  { id: 0, src: "/starLogoAlt.svg", alt: "Star Logo" },
  { id: 1, src: "/caseAlt.svg", alt: "Case Icon" },
  { id: 2, src: "/eyeAlt.svg", alt: "Eye Icon" },
  { id: 3, src: "/framingAlt.svg", alt: "Framing Icon" },
  { id: 4, src: "/paintAlt.svg", alt: "Paint Icon" },
  { id: 5, src: "/playAlt.svg", alt: "Play Icon" },
  { id: 6, src: "/scholarAlt.svg", alt: "Scholar Icon" },
  { id: 7, src: "/tvAlt.svg", alt: "TV Icon" },
  { id: 8, src: "/starAlt.svg", alt: "Star Alt Icon" },
  { id: 9, src: "/personAlt.svg", alt: "Person Icon" },
  { id: 10, src: "/writingAlt.svg", alt: "Writing Icon" },
  { id: 11, src: "/heart.svg", alt: "heart Icon" },
];

const AddItemDropDown: React.FC<AddItemDropDownProps> = ({
  children,
  itemID,
  itemType,
}) => {
  const [selectedBoardId, setSelectedBoardId] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const { addItem } = useBoard();
  const queryClient = useQueryClient();

  const { filterOption, setQuery } = useFilter({
    options: {
      pagination: false,
    },
  });
  const updateSearchQuery = useCallback(
    debounce((query) => {
      setQuery({ searchQuery: query });
    }, 300),
    [],
  );

  const { getAllBoard, deleteItem } = useBoard();

  const { data, error, isLoading, refetch } = useQuery<GetAllBoardResponse>({
    queryKey: ["boards", filterOption],
    queryFn: () =>
      getAllBoard({
        params: filterOption,
      }),
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    updateSearchQuery(event.target.value);
  };

  const mutation: UseMutationResult<
    AddItemResponse,
    Error,
    AddItemParams,
    unknown
  > = useMutation({
    mutationFn: addItem,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      toast.success("Item added successfully!", {
        id: "item-add",
      });
      refetch();
    },
    onError: (error: Error) => {
      toast.error("Failed to add item. Please try again.", {
        id: "item-add",
      });
    },
    onMutate: () => {
      toast.loading("Adding the item...", {
        id: "item-add",
      });
    },
  });

  const deleteItemFromBoard: UseMutationResult<
    AddItemResponse,
    Error,
    DeleteItemParam
  > = useMutation({
    mutationFn: (params: DeleteItemParam) => deleteItem(params), // Call deleteItem with params
    onSuccess: (data) => {
      toast.success(`Item deleted successfully: ${data.message}`, {
        id: "delete",
      }); // Notify on success
      queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      refetch();
    },
    onError: (error: Error) => {
      toast.error(`Failed to delete item: ${error.message}`, {
        id: "delete",
      }); // Notify on error
    },
    onMutate: () => {
      toast.loading("Deleting the item...", {
        id: "delete",
      });
    },
  });

  const handleDelete = (boardId: string, itemId: string) => {
    deleteItemFromBoard.mutate({ boardId, itemId }); // Trigger mutation for deletion
  };

  const handleAddItem = (BoardId: string) => {
    if (!BoardId) {
      toast.error("Please select a board first!");
      return;
    }

    const newItem: AddItemParams = {
      itemType,
      boardId: BoardId,
      itemId: itemID,
    };
    mutation.mutate(newItem);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const sortedBoards = data?.data?.data ?? [];

  const filteredBoards = sortedBoards.filter(
    (board) => !board.isDeleted && board.isActive,
  );

  const recentAddedBoards = filteredBoards
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )
    .slice(0, 2);

  const recentUpdatedBoards = filteredBoards
    .filter(
      (board) =>
        !recentAddedBoards.some((addedBoard) => addedBoard.id === board.id),
    )
    .sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    )
    .slice(0, 3);

  const recentBoards = [...recentAddedBoards, ...recentUpdatedBoards];

  const allBoards = sortedBoards
    .filter((board) => !board.isDeleted && board.isActive)
    .filter((board) => !recentBoards.some((recent) => recent.id === board.id)); // Exclude recent boards

  return isDesktop ? (
    <DropdownMenu open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent
        className="max-w-[300px] w-full min-h-[300px] border-[1px] border-bdr-10 border-solid  rounded-none p-0 z-[100]"
        align="end"
      >
        <DropdownMenuGroup className="z-20 flex flex-col gap-2 text-base text-gray-60 font-sh5">
          <ProfileForm
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            setSelectedBoardId={setSelectedBoardId}
            boards={allBoards}
            recentBoards={recentBoards}
            handleAddItem={handleAddItem}
            selectedBoardId={selectedBoardId}
            itemID={itemID}
            handleDelete={handleDelete}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
      <DialogTrigger
        onClick={toggleDrawer}
        className="text-lg bg-transparent text-other-cta"
        asChild
      >
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none ">
        <DrawerHeader className="p-0"></DrawerHeader>
        <ProfileForm
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
          setSelectedBoardId={setSelectedBoardId}
          boards={allBoards}
          recentBoards={recentBoards}
          handleAddItem={handleAddItem}
          selectedBoardId={selectedBoardId}
          itemID={itemID}
          handleDelete={handleDelete}
        />
      </DrawerContent>
    </Drawer>
  );
};

type Board = {
  boardItems: any;
  icon: number;
  id: string;
  name: string;
};

type ProfileFormProps = {
  className?: string;
  boards?: Board[];
  setSelectedBoardId: (id: string) => void;
  handleAddItem: (id: string) => void;
  searchQuery: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedBoardId: string;
  itemID: string;
  recentBoards?: Board[];
  updatedBoards?: Board[];
  handleDelete: (boardId: string, itemId: string) => void;
};

function ProfileForm({
  className,
  boards,
  recentBoards,
  setSelectedBoardId,
  selectedBoardId,
  handleAddItem,
  searchQuery,
  handleSearchChange,
  itemID,
  handleDelete,
}: ProfileFormProps) {
  return (
    <form className={cn("grid items-center pt-5 ", className)}>
      <div className="relative px-5 pb-5 border-[#EAEAEA] border-b-[1px] border-solid">
        <input
          onChange={handleSearchChange}
          className="w-full border-[1px] min-w-[232px] pl-12 border-solid border-gray-20 focus:border-black focus:ring-0 h-10 rounded-[50px] transition-colors duration-300 ease-in-out focus:outline-none focus:ring-5 focus:ring-focus-ring focus:ring-opacity-50"
          placeholder="Search"
          value={searchQuery}
        />
        <img
          className="absolute pl-2 transform -translate-y-1/2 left-7 top-5"
          src="/iconsearch.svg"
          alt="Search Icon"
        />
      </div>
      <div className="px-8 pt-6 mq450:p-5 overflow-auto max-h-[255px]">
        <p className="text-[#BCBCBC] text-base font-sh5 font-normal">Recent</p>
        <div className="relative flex flex-col">
          {recentBoards?.map((board) => {
            const imageSource = imageSources.find(
              (image) => image.id === board.icon,
            );
            const existingItemIds = board.boardItems.map(
              (item: { _id: any }) => item._id,
            );
            const handleItemAction = (boardId: string, isInBoard: boolean) => {
              isInBoard
                ? handleDelete(boardId, itemID)
                : handleAddItem(boardId);
            };

            const isItemAlreadyInBoard = existingItemIds.includes(itemID);
            const isSelected = board.id === selectedBoardId;
            return (
              <div
                key={`board-${board.id}`}
                onClick={() => handleItemAction(board.id, isItemAlreadyInBoard)}
                className={`flex flex-row group items-center border-b-[1px] border-solid border-[#E5E5E5] py-4 justify-start gap-2 cursor-pointer bg-transparent w-full`}
              >
                <img
                  className="w-6 h-6"
                  src={imageSource ? imageSource.src : ""}
                  alt={imageSource ? imageSource.alt : ""}
                />
                <div className="relative leading-[140%] inline-block min-w-[88px] shrink-0 whitespace-nowrap font-normal text-lg font-sh5 truncate max-w-[200px]">
                  {board.name}
                </div>
                {/* Display side-panel-icons.svg only if the item is not selected */}
                {!isSelected && !isItemAlreadyInBoard && (
                  <img
                    className="absolute right-0 invisible w-6 h-6 group-hover:visible"
                    src="/side-panel-icons.svg"
                    alt="Item not selected"
                  />
                )}
                {/* Always display tickmark.svg if the item is selected or already in board */}
                {(isSelected || isItemAlreadyInBoard) && (
                  <img
                    className="absolute right-0 w-6 h-6"
                    src="/tickmark.svg"
                    alt="Item selected or already added"
                  />
                )}
              </div>
            );
          })}
        </div>

        <p className="text-[#BCBCBC] text-base font-sh5 font-normal mt-4">
          All Boards
        </p>
        <div className="relative flex flex-col">
          {boards?.map((board) => {
            const imageSource = imageSources.find(
              (image) => image.id === board.icon,
            );
            const existingItemIds = board.boardItems.map(
              (item: { _id: any }) => item._id,
            );
            const handleItemAction = (boardId: string, isInBoard: boolean) => {
              isInBoard
                ? handleDelete(boardId, itemID)
                : handleAddItem(boardId);
            };
            const isItemAlreadyInBoard = existingItemIds.includes(itemID);
            const isSelected = board.id === selectedBoardId;
            return (
              <div
                key={`board-${board.id}`}
                onClick={() => handleItemAction(board.id, isItemAlreadyInBoard)}
                className={`group border-b-[1px] border-solid border-[#E5E5E5] py-4 flex flex-row items-center justify-start gap-2 cursor-pointer 
                 bg-transparent
                }`}
              >
                <img
                  className="w-6 h-6"
                  src={imageSource ? imageSource.src : ""}
                  alt={imageSource ? imageSource.alt : ""}
                />
                <div className="relative leading-[140%] inline-block min-w-[88px] shrink-0 whitespace-nowrap font-normal text-lg font-sh5">
                  {board.name}
                </div>

                {/* Display side-panel-icons.svg only if the item is not selected */}
                {!isSelected && !isItemAlreadyInBoard && (
                  <img
                    className="absolute right-0 invisible w-6 h-6 group-hover:visible"
                    src="/side-panel-icons.svg"
                    alt="Item not selected"
                  />
                )}
                {/* Always display tickmark.svg if the item is selected or already in board */}
                {(isSelected || isItemAlreadyInBoard) && (
                  <img
                    className="absolute right-0 w-6 h-6"
                    src="/tickmark.svg"
                    alt="Item selected or already added"
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="bg-[#F2F2F2] px-3 py-5">
        <NewBoard>
          <Button
            size="default"
            className="w-[156px] rounded-[50px] text-sm font-sh5"
          >
            <img src="/plus-icon.svg" alt="" className="mr-2" /> New Board
          </Button>
        </NewBoard>
      </div>
    </form>
  );
}

export default AddItemDropDown;
