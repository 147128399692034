import SingleCardDropDown from "@/components/Boards/SingleCardDropDown";
import React from "react";
import { useNavigate } from "react-router-dom";

interface CardProps {
  logoimageSrc: string;
  cardImg: string;
  mainText: string;
  subText: string;
  boardId: string;
  pin?: boolean;
}

const Card: React.FC<CardProps> = ({
  cardImg,
  logoimageSrc,
  mainText,
  subText,
  boardId,
  pin,
}) => {
  const navigate = useNavigate();

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  // Define the navigation handler
  const handleClick = () => {
    console.log(`Navigating to board: ${boardId}`);
    navigate(`/boards/${boardId}`); // Navigate to the board's page
  };

  return (
    <div
      onClick={handleClick}
      className="w-[297px] group cursor-pointer mq750:w-full mq750:min-w-[297px] mq450:w-full h-[220px] relative pt-4 mq450:pt-0"
    >
      {/* Image and gradient overlay */}
      <div className="relative w-full h-full">
        {cardImg ? (
          <img src={cardImg} className="object-cover w-full h-full" />
        ) : (
          <div className="w-full h-full bg-bg-5"></div>
        )}
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-gray-200 to-black"></div>
      </div>

      {/* Dropdown component */}
      <SingleCardDropDown pin={pin} boardId={boardId}>
        <img
          onClick={(e) => e.stopPropagation()} // Stop event from bubbling up
          className="absolute right-0 invisible pr-3 top-6 mq450:top-3 group-hover:visible"
          src="/ellipsis.svg"
        />
      </SingleCardDropDown>

      {/* Content */}
      <div className="absolute bottom-0 w-full p-5">
        <img src={logoimageSrc} alt="Logo" />
        <p className="text-sm font-normal text-white truncate font-sh5 ">
          {capitalizeFirstLetter(mainText)}
        </p>
        <p className="font-normal font-sh5 text-xs text-[#CCCCCC]">{subText}</p>
      </div>
    </div>
  );
};

export default Card;
