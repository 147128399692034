import React from "react";
import { Button } from "@/components/ui/button";
import { Share2, Menu } from "lucide-react";

export default function ArtworkDisplay() {
  return (
    <div className="flex flex-col min-h-screen lg:bg-[#F6F6F6] w-full">
      <header className="flex items-center justify-between px-4 py-4 bg-white shadow-sm sm:px-6">
        <div className="text-xl font-bold">ASIGN</div>
        <div className="hidden space-x-2 sm:flex">
          <Button className="rounded-full p-[16px_29px]">CONTINUE</Button>
          <Button className="rounded-full p-[16px_29px]">GET ASIGN</Button>
        </div>
        <Button variant="ghost" size="icon" className="sm:hidden">
          <Menu className="w-6 h-6" />
        </Button>
      </header>

      <main className="flex justify-center flex-grow w-full px-4 py-10 font-normal font-sh5 mq750:py-5">
        <div className="w-full max-w-md bg-white sm:shadow-md sm:rounded-lg ">
          <div className="lg:p-8">
            <div className="bg-[#F6F6F6]">
              <img
                src="https://picsum.photos/200/200"
                alt="Abstract Artwork"
                className="object-contain w-full p-[32px_32px_64px_32px] mq750:p-[16px_16px_36px_16px]"
              />
            </div>
            <div className="flex items-center justify-between mt-10 mb-3 mq750:mt-4">
              <h2 className="text-5xl font-normal font-eb">
                Kanhaiyo Lal Sharma
              </h2>
              <button
                className="flex items-center gap-x-2 text-gray-60"
                type="button"
              >
                <img src="/share1.svg" alt="" />{" "}
                <span className="mq750:hidden">Share</span>
              </button>
            </div>
            <p className="text-lg text-gray-60">Moon (Quiet Yellow Water)</p>
            <p className="mb-3 text-lg text-gray-60">1992</p>
            <p className=" text-gray-60">Charcoal, Compressed on Canvas</p>
            <p className=" text-gray-60">24 cm x 24 cm (9.4 in x 9.4 in)</p>
            <div className="mt-10 border-t border-solid border-bdr-10 mb-[21px] mq750:mt-6">
              <h3 className="mt-10 mb-5 text-5xl font-normal mq750:mt-6 mq750:text-lg mq750:mb-4">
                Find out more on Asign
              </h3>
              <p className="mb-5 text-gray-60">
                Explore this object in detail and discover new artists and
                experiences by joining Asign.
              </p>
              <Button className="p-[16px_29px] rounded-full uppercase mq750:w-full mq750:px-[14px]">
                GET ASIGN
              </Button>
            </div>
            <p className="flex items-center mt-5 text-gray-60 gap-x-2">
              Already have an account?
              <a
                href="#"
                className="pb-1 text-sm font-medium text-black uppercase border-b border-solid"
              >
                Log in
              </a>
            </p>
          </div>
        </div>
      </main>

      <footer className="w-full pb-4 text-sm text-center text-gray-60 lg:bg-[#F6F6F6]  px-5">
        <a href="#" className="hover:underline">
          Terms & Conditions
        </a>
        {" | "}
        <a href="#" className="hover:underline">
          Privacy Policy
        </a>
        {" | "}
        <a href="#" className="hover:underline">
          Contact us
        </a>
        <span className="mt-1 ">
          &nbsp; | © 2024 Democraft Technologies Private Limited
        </span>
      </footer>
    </div>
  );
}
