import React, { ReactNode } from "react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "@mui/material";
import useBoard from "@/apis/board";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import RemoveItem from "@/modal/RemoveItem";
import AddTag from "@/modal/AddTag";
import RemoveCaption from "@/modal/RemoveCaption";

import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { GetSingleBoardResponse } from "@/types/boards.type";
import { CreateBoardParams } from "@/hooks/useFilter";
import toast from "react-hot-toast";
import AddCaption from "@/modal/AddCaption";
import ShareModal from "../ShareModal";

interface ArtistDropDownProp {
  children: ReactNode;
  boardId: string;
  itemId: string;
  caption?: string;
  itemType: string;
  coverId: string;
  tagList: string[];
  imgSrc: string;
}

const ArtistDropDown: React.FC<ArtistDropDownProp> = ({
  children,
  boardId,
  itemId,
  coverId,
  caption,
  itemType,
  imgSrc,
  tagList,
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { editSingleBoard } = useBoard();

  const queryClient = useQueryClient();
  const coverMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    { boardId: string; params: CreateBoardParams }
  > = useMutation({
    mutationFn: ({ boardId, params }) => editSingleBoard({ boardId, params }), // Pass both boardId and params
    onSuccess: () => {
      toast.success("Cover updated successfully!", {
        id: "update-cover",
      });
      queryClient.invalidateQueries({ queryKey: ["single-board"] });
    },
    onError: (error: Error) => {
      toast.error("Failed to update cover. Please try again.", {
        id: "update-cover",
      });
    },
    onMutate: () => {
      toast.loading("Updating cover...", {
        id: "update-cover",
      });
    },
  });

  const handleCover = () => {
    coverMutation.mutate({
      boardId,
      params: { coverId },
    });
  };
  return isDesktop ? (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="border-[1px] border-bdr-10 border-solid p-2 min-w-[200px] rounded-none">
        <DropdownMenuGroup className="flex flex-col gap-2 text-base text-gray-60 font-sh5">
          <ProfileForm
            setCover={handleCover}
            itemType={itemType}
            boardId={boardId}
            itemId={itemId}
            caption={caption}
            imgSrc={imgSrc}
            tagList={tagList}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Drawer>
      <DialogTrigger className="text-white bg-transparent ">
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader className="p-0"></DrawerHeader>
        <ProfileForm
          setCover={handleCover}
          itemType={itemType}
          boardId={boardId}
          itemId={itemId}
          caption={caption}
          imgSrc={imgSrc}
          tagList={tagList}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  boardId: string;
  itemId: string;
  imgSrc: string;
  caption?: string;
  itemType: string;
  tagList: string[];
  setCover: () => void;
};

function ProfileForm({
  className,
  boardId,
  itemId,
  caption,
  itemType,
  setCover,
  imgSrc,
  tagList,
}: ProfileFormProps) {
  console.log(caption);
  return (
    <form className={cn("grid items-center gap-y-6 p-6 ", className)}>
      {caption && (
        <RemoveCaption boardId={boardId} itemId={itemId}>
          <div className="text-base font-normal text-left cursor-pointer text-gray-60 mq450:text-left font-sh5">
            Remove Caption
          </div>
        </RemoveCaption>
      )}
      <AddCaption
        boardId={boardId}
        itemId={itemId}
        captionImg={imgSrc}
        defalutCaption={caption}
      >
        <div className="text-base font-normal text-left cursor-pointer text-gray-60 mq450:text-left font-sh5">
          {caption ? "Edit Caption" : "Add Caption"}
        </div>
      </AddCaption>
      <AddTag
        boardId={boardId}
        itemId={itemId}
        itemType={itemType}
        tagList={tagList}
      >
        {" "}
        <div className="text-base font-normal cursor-pointer text-start text-gray-60 mq450:text-left font-sh5">
          Add Tags
        </div>
      </AddTag>
      {itemType !== "video" &&
        itemType !== "writing" &&
        itemType !== "file" && (
          <ShareModal
            url={`asign-discover.pages.dev/${itemType}/detail/${itemId}`}
          >
            <div className="text-base font-normal cursor-pointer text-start text-gray-60 mq450:text-left font-sh5">
              Share
            </div>
          </ShareModal>
        )}
      <div
        onClick={(e) => {
          setCover();
          e.stopPropagation();
          e.preventDefault();
        }}
        className="text-base font-normal cursor-pointer text-start text-gray-60 mq450:text-left font-sh5"
      >
        Make Cover
      </div>
      <RemoveItem boardId={boardId} itemId={itemId}>
        <div className="text-base font-normal cursor-pointer text-start text-gray-60 mq450:text-left font-sh5">
          Remove from Board
        </div>{" "}
      </RemoveItem>
    </form>
  );
}

export default ArtistDropDown;
