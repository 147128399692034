import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import useMediaQuery from "@/hooks/use-media-query";
import { Button } from "@/components/ui/button";

interface GlobalProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  cancelText?: string;
  actionText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const Global = ({ children, ...props }: GlobalProps) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [open, setOpen] = useState(false);

  const data = {
    title: props.title ?? "Are you sure?",
    description: props.description ?? "This action cannot be undone.",
    cancelText: props.cancelText ?? "Cancel",
    actionText: props.actionText ?? "Continue",
    onConfirm: props.onConfirm,
    onCancel: props.onCancel,
  };

  const handleConfirm = () => {
    if (data.onConfirm) data.onConfirm();
    setOpen(false);
  };

  const handleCancel = () => {
    if (data.onCancel) data.onCancel();
    setOpen(false);
  };

  return isDesktop ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild className="w-full h-full">
        {children}
      </DialogTrigger>
      <DialogContent className="min-w-[640px] z-[100]">
        <DialogHeader className="px-8">
          <DialogTitle className="border-[#E5E5E5] pb-4 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row items-center justify-between">
              <p>{data.title}</p>
              <DialogClose asChild className="bg-transparent cursor-pointer">
                <img className="cursor-pointer" src="/close1.svg" />
              </DialogClose>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="px-8 font-normal text-center font-sh5 font-base text-gray-60">
          <p className="">{data.description}</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full p-5 bg-[#F2F2F2]">
          <Button
            onClick={handleCancel}
            className="cursor-pointer h-6 font-medium text-sm font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none px-0 hover:no-underline "
            type="button"
            variant="link"
          >
            {data.cancelText}
          </Button>
          <Button
            onClick={handleConfirm}
            className="rounded-[50px]  h-[42PX] mq750:px-5 font-medium text-sm text-white font-sh5 px-[36px] py-2"
            variant="default"
            type="button"
          >
            {data.actionText}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger onClick={() => setOpen(true)} asChild>
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader className="text-left border-b border-solid border-gray-10">
          <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
            <div className="flex flex-row items-center justify-between">
              {data.title}
              <DialogClose asChild className="bg-transparent cursor-pointer">
                <img className="cursor-pointer" src="/close1.svg" />
              </DialogClose>
            </div>
          </DrawerTitle>
        </DrawerHeader>
        <div className="p-5 font-normal text-center font-sh5 font-base text-gray-60">
          <p className="">{data.description}</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full p-5 bg-[#F2F2F2]">
          <Button
            onClick={handleCancel}
            className="cursor-pointer h-6 font-medium text-xs font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none px-0 hover:no-underline"
            type="button"
            variant="link"
          >
            {data.cancelText}
          </Button>
          <Button
            onClick={handleConfirm}
            className="rounded-[50px]  h-[42PX] mq750:px-5 font-medium text-sm text-white font-sh5 px-[36px] py-2"
            variant="default"
            type="button"
          >
            {data.actionText}
          </Button>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default Global;
