import React, { useState, useRef } from "react";
import GlobalDialog from "@/modal/GlobalDialog";

const UploadImage = ({ children }: { children: React.ReactNode }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // Handle clicking on the upload area to trigger the file input
  const handleClickUploadArea = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle the drag-and-drop functionality
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    handleFileValidation(droppedFile);
  };

  // Handle file change (from file input)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    handleFileValidation(selectedFile);
  };

  // Validate file (size and type)
  const handleFileValidation = (file: File | null) => {
    if (!file) return;

    // Validate file type
    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    if (!allowedTypes.includes(file.type)) {
      alert(
        "Unsupported file type. Please upload a valid image (JPG, PNG, WEBP)."
      );
      return;
    }

    // Validate file size (max 20MB)
    const maxSize = 20 * 1024 * 1024; // 20 MB
    if (file.size > maxSize) {
      alert("File size exceeds 20MB.");
      return;
    }

    // If validation passes, proceed
    setFile(file);
    setLoading(true);
    previewFile(file);
  };

  // Generate a preview of the selected file
  const previewFile = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result as string);
      setLoading(false);
      setUploadComplete(true);
    };
    reader.readAsDataURL(file);
  };

  return (
    <GlobalDialog TriggerButton={children}>
      <div className="">
        <div
          onClick={handleClickUploadArea}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          className={`w-full cursor-pointer bg-[#F6F6F6] border-[1px] border-dotted border-gray-40 flex flex-col items-center justify-center gap-3 py-8 ${
            loading ? "bg-[#e0e0e0]" : ""
          }`}
        >
          <input
            ref={fileInputRef}
            type="file"
            accept=".jpg,.jpeg,.png,.webp"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {!previewUrl && !loading && (
            <>
              <img
                className="w-[24px] h-[24px]"
                src="/add_image.svg"
                alt="Add"
              />
              <p className="text-lg font-normal text-gray-100 font-sh5">
                Drag and drop or browse files
              </p>
              <p className="text-sm font-normal font-sh5 mq450:text-center text-gray-60">
                Up to 20 MB, in .jpg, .png, or .webp format
              </p>
            </>
          )}
          {loading && (
            <div className="flex items-center justify-center w-full mt-4">
              <div className="inline-block px-2 py-1 text-xs font-semibold text-teal-600 bg-teal-200 rounded-full">
                Uploading...
              </div>
            </div>
          )}
          {uploadComplete && !loading && (
            <p className="mt-4 font-semibold text-other-cta">
              File uploaded, hit on save
            </p>
          )}
          {previewUrl && !loading && (
            <div className="px-8 py-5">
              {file?.type.startsWith("image/") && (
                <img src={previewUrl} alt="Preview" className="w-[200px]" />
              )}
            </div>
          )}
        </div>
        <p className="pt-5 text-sm font-normal font-sh5 text-gray-60">
          Upload an image from an exhibition installation or an image of
          objects.
        </p>
      </div>
    </GlobalDialog>
  );
};

export default UploadImage;
