import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import useMediaQuery from "@/hooks/use-media-query";
import React, { useState } from "react";

const GlobalDialog = ({
  children,
  TriggerButton,
}: {
  children: React.ReactNode;
  TriggerButton: React.ReactNode;
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  return isDesktop ? (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger
        className="text-lg text-white bg-transparent"
        onClick={() => setIsOpen(true)}
        asChild
      >
        {TriggerButton}
      </DialogTrigger>
      <DialogContent className="min-w-[640px]">
        <DialogHeader className="px-8">
          <DialogTitle className="pb-5 border-[#E5E5E5] border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row items-center justify-between">
              <p>Upload File</p>
              <DialogClose asChild>
                <img src="/close1.svg" alt="Close" />
              </DialogClose>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="grid items-center gap-5 px-8 mq450:gap-8">
          {children}
        </div>
        <div className="flex mt-2 flex-row justify-between w-full px-5 py-3 bg-[#F2F2F2]">
          <DialogClose asChild>
            <button
              className="relative text-sm font-bold text-gray-100 underline bg-transparent rounded-none"
              type="button"
            >
              CANCEL
            </button>
          </DialogClose>
          <Button
            className="rounded-[50px] px-4 font-medium text-sm text-white"
            variant="default"
            type="button"
          >
            UPLOAD
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger
        className="text-lg text-white bg-transparent"
        onClick={() => setIsOpen(true)}
        asChild
      >
        {TriggerButton}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader>
          <div className="flex flex-row items-center justify-between">
            <p>Upload File</p>
            <DialogClose asChild>
              <img src="/close1.svg" alt="Close" />
            </DialogClose>
          </div>
        </DrawerHeader>
        <div className="grid items-center gap-5 px-8 py-5 mq450:gap-8">
          {children}
        </div>
        <div className="flex mt-2 flex-row justify-between w-full px-5 py-3 bg-[#F2F2F2]">
          <DialogClose asChild>
            <button
              className="relative text-sm font-bold text-gray-100 underline bg-transparent rounded-none"
              type="button"
            >
              CANCEL
            </button>
          </DialogClose>
          <Button
            className="rounded-[50px] px-4 font-medium text-sm text-white"
            variant="default"
            type="button"
          >
            UPLOAD
          </Button>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default GlobalDialog;
