import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface ItemTypeFilterProps {
  onSelect: (type: string) => void;
  selectedType: string;
}

const ItemTypeFilter: React.FC<ItemTypeFilterProps> = ({
  onSelect,
  selectedType,
}) => {
  // Default value for the dropdown trigger
  const displayText = selectedType || "Show All";

  const displayLabelByValue = {
    object: "Object",
    artist: "Artists",
    business: "Business",
    events: "Events",
    writing: "Writing",
    text: "Texts",
    file: "Images",
    video: "Videos",
    link: "Link",
  };

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger className="cursor-pointer text-sm font-sh5 nsh  bg-transparent border-[#E8E8E8] px-5 py-3 rounded-[50px] border-[1px] border-solid capitalize w-[120px] flex items-center justify-between">
          <span className="truncate">
            {displayText == ""
              ? "Show All"
              : displayLabelByValue[
                  selectedType as keyof typeof displayLabelByValue
                ] || "Show All"}
          </span>
          <img className="ml-2" src="/arrow.svg" alt="arrow" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="">
          <DropdownMenuItem onClick={() => onSelect("")}>
            Show All
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect("object")}>
            Object
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect("artist")}>
            Artists
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect("business")}>
            Business
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect("events")}>
            Events
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect("writing")}>
            Writing
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect("text")}>
            Texts
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect("file")}>
            Images
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect("video")}>
            Videos
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onSelect("link")}>
            Link
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ItemTypeFilter;
