import artist from "@/apis/artist";
import Header from "@/components/Global/header/Header";
import { FollowArtistButton } from "@/components/Templates/Artists/BigBanner";
import { Avatar, AvatarImage, AvatarFallback } from "@radix-ui/react-avatar";
import { Camera, Edit2 } from "lucide-react";
import { format } from "path";
import React, { useEffect, useRef, useState } from "react";
import UploadImage from "./UploadImage";
import { MobileHeader } from "@/components/Global/header/MobileHeader";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Switch } from "@/components/ui/switch";
const sideBar = [
  { label: "Biography" },
  { label: "Catalogue rasionné" },
  { label: "Representation" },
  { label: "Education" },
  { label: "Events" },
  { label: "Recognition" },
  { label: "Press" },
  { label: "Notable collections" },
  { label: "Publications" },
];
const Profile = () => {
  const [currentSection, setCurrentSection] = useState<string>("");

  const refs = sideBar.reduce((acc, value) => {
    acc[value.label] = useRef<HTMLDivElement>(null);
    return acc;
  }, {} as { [key: string]: React.RefObject<HTMLDivElement> });

  const scrollToSection = (label: string) => {
    const ref = refs[label]?.current;

    if (ref) {
      setCurrentSection(label);
      ref.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "-10px",
      threshold: 0.7,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.getAttribute("data-section") || "");
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    Object.values(refs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [refs]);
  return (
    <div className="container font-normal font-sh5">
      <Header />
      <div className="fixed z-10 w-full top-15 mb-15">
        <MobileHeader
          data={sideBar}
          active={currentSection}
          onClick={scrollToSection}
        />
      </div>
      <TopSection />
      <div className="relative flex p-5 mt-5 lg:px-[48px]">
        <div className="hidden lg:w-1/3 md:block">
          <div className="overflow-y-scroll text-gray-60">
            {sideBar.map((item) => (
              <div
                role="button"
                key={item.label}
                tabIndex={0}
                className={cn(
                  "mb-[20px] cursor-pointer whitespace-nowrap hover:text-gray-100",
                  currentSection === item.label && "text-gray-100"
                )}
                onClick={() => scrollToSection(item.label)}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
        <div className="lg:w-2/3 lg:max-h-[500px] overflow-y-scroll">
          <div ref={refs["Biography"]} data-section="Biography">
            <h3 className="font-medium text-[24px]">Biography</h3>
            {/* <p className="my-5">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Voluptatum magnam cupiditate, culpa quisquam, possimus
              praesentium, consequatur atque enim repellat quos obcaecati optio?
              Ipsam repellat asperiores quasi ea consequatur labore libero.
            </p>
            <Button variant="outline" className="rounded-full">
              READ MORE
            </Button> */}
            <AddBox
              image="/writing.svg"
              description="Tell us about the person behind the art."
              addButtonTitle="ADD"
              className="mt-10"
            />
          </div>
          <div ref={refs["Representation"]} data-section="Representation">
            <h3 className="font-medium text-[24px]">Representation</h3>
            <AddBox
              image="/writing.svg"
              description="Galleries that represent you."
              addButtonTitle="ADD"
              className="mt-10"
            />
            {/* <div>
              <Representation data={artist?.represents || []} />
            </div> */}
          </div>
          <div ref={refs["Education"]} data-section="Education">
            <h3 className="font-medium text-[24px]">Education</h3>
            <AddBox
              image="/writing.svg"
              description="Your academic qualifications."
              addButtonTitle="ADD"
              className="mt-10"
            />
            {/* <div className="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-10">
              {artist?.education.map((item) => (
                <div key={item.id}>
                  <p>{item.courseName}</p>
                  <p className="mt-1 text-sm text-gray-60">
                    {format(new Date(item.startDate), "yyyy")} -{" "}
                    {format(new Date(item.endDate), "yyyy")}
                  </p>
                </div>
              ))}
            </div>
            <Button variant="outline" className="mt-10 rounded-full">
              VIEW MORE
            </Button> */}
          </div>
          <div ref={refs["Events"]} data-section="Events">
            <h3 className="font-medium text-[24px]">Events</h3>
            {/* <div className="grid grid-cols-2 mt-10 gap-y-10">
              {artist?.events.map((item) => (
                <div key={item.id} className="">
                  <img
                    src="https://picsum.photos/300/200"
                    alt={item.eventName}
                  />

                  <div className="grid gap-1 mt-5">
                    <h4 className="font-normal capitalize">{item.eventName}</h4>
                    <p className="text-sm text-gray-60">{item.country}</p>
                    <p className="text-sm text-gray-60">
                      {format(new Date(item.startDate), "MMM d, yyyy")} -{" "}
                      {format(new Date(item.endDate), "MMM d, yyyy")}{" "}
                    </p>
                    <p className="text-sm text-gray-60">
                      {" "}
                      {format(new Date(item.startDate), "h:mm a")} -{" "}
                      {format(new Date(item.endDate), "h:mm a")}
                    </p>
                  </div>
                </div>
              ))}
            </div> */}
            <AddBox
              image="/writing.svg"
              description="Your exhibitions, talks you've been 
                            invited to, and more."
              addButtonTitle="ADD"
              className="mt-10"
            />
          </div>
          <div ref={refs["Recognition"]} data-section="Recognition">
            <h3 className="font-medium text-[24px]">Recognition</h3>
            <AddBox
              image="/writing.svg"
              description="Your honours and awards."
              addButtonTitle="ADD"
              className="mt-10"
            />
            {/* <div className="grid grid-cols-2 mt-10 gap-y-10">
              {artist?.recognition.map((item) => (
                <div key={item.id}>
                  <p>{item.artName}</p>
                  <p className="text-sm text-gray-60">1994 - 1997</p>
                </div>
              ))}
            </div>
            <Button variant="outline" className="mt-10 rounded-full">
              VIEW MORE
            </Button> */}
          </div>
          <div ref={refs["Press"]} data-section="Press">
            <h3 className="font-medium text-[24px]">Press</h3>
            <AddBox
              image="/writing.svg"
              description="Your media coverage."
              addButtonTitle="ADD"
              className="mt-10"
            />
            {/* <div className="grid grid-cols-2 mt-10 gap-y-10">
              {artist?.press.map((item) => (
                <div key={item.id}>
                  <p>{item.articleName}</p>
                  <p className="mt-2 mb-5 text-sm text-gray-60">
                    {format(new Date(item.articleDate), "yyyy")}
                  </p>
                  <a
                    className="font-medium underline "
                    href={item.articleLink}
                    target="blank"
                  >
                    VIEW IMAGE
                  </a>
                </div>
              ))}
            </div>
            <Button variant="outline" className="mt-10 rounded-full">
              VIEW MORE
            </Button> */}
          </div>
          <div
            ref={refs["Notable collections"]}
            data-section="Notable collections"
          >
            <h3 className="font-medium text-[24px]">Notable collections</h3>
            <AddBox
              image="/writing.svg"
              description="Prominent collections that include your work."
              addButtonTitle="ADD"
              className="mt-10"
            />
            {/* <div className="grid grid-cols-2 mt-10 gap-y-10">
              {artist?.notableCollections.map((item) => (
                <div key={item.id}>
                  <p>{item.collectionName}</p>
                  <p className="mt-1 text-sm text-gray-60">
                    {item.state}, {item.country}
                  </p>
                </div>
              ))}
            </div>
            <Button variant="outline" className="mt-10 rounded-full">
              VIEW MORE
            </Button> */}
          </div>
          <Separator className="my-[42px]" />
          <div ref={refs["Publications"]} data-section="Publications">
            <h3 className="font-medium text-[24px]">Publications</h3>
            <AddBox
              image="/writing.svg"
              description="Exhibition catalogues and any other publications about your work."
              addButtonTitle="ADD"
              className="mt-10"
            />
            {/* <div className="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-10">
              {artist?.publications.map((item) => (
                <div key={item.id}>
                  <p>{item.publicationName}</p>
                  <p className="mt-1 text-sm text-gray-60">
                    {item.publicationBusiness} -{" "}
                    {format(new Date(item.publicationDate), "MMM dd, yyyy")}
                  </p>
                </div>
              ))}
            </div>
            <Button variant="outline" className="mt-10 rounded-full">
              VIEW MORE
            </Button> */}
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Profile;

const TopSection = () => {
  return (
    <>
      <div className="flex mb-[48px] mt-[60px]  lg:px-[48px]">
        <h1 className="text-[40px]  leading-tight mb-4 font-normal lg:w-1/3">
          Build your artist profile
        </h1>
        <div className="lg:w-2/3">
          <div className="max-w-[600px]">
            <p className="mb-4 text-gray-100">
              Get discovered by art lovers, businesses, and other artists.
            </p>
            <p className="text-gray-100">
              Your Asign page tells the story of your unique creative journey.
              Share details about upcoming shows, gallery representation,
              recognition, and more.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse  p-5 lg:mt-8 md:flex-row md:items-center lg:px-[48px]">
        <div className="lg:w-1/3">
          <Avatar className="">
            <AvatarImage
              src="/frame-1.jpg"
              className="w-[100px] h-[100px] rounded-full object-cover"
            />
            <AvatarFallback>{"CN"}</AvatarFallback>
          </Avatar>
          <h2 className="font-normal font-eb md:text-[48px] text-gray-80 text-[36px] max-w-[100px] leading-[57px] ">
            Mrinalini Mukherjee
          </h2>
        </div>
        <div className="relative lg:w-2/3">
          <img
            src="/frame-1.jpg"
            alt=""
            className="w-full max-h-[437px] object-cover object-bottom"
          />
          <div className="absolute w-[100px] h-[100px] rounded-full bg-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex items-center justify-center">
            <img src="/image-icon.svg" alt="" className="w-[36px] h-[36px]" />
          </div>
          {/* uplaod icons */}
          <UploadImage>
            <div className="absolute w-[48px] h-[48px] bg-white bottom-6 right-6 rounded-full flex justify-center items-center cursor-pointer">
              <img src="/camera.svg" alt="" className="w-[24px] h-[24px]" />
            </div>
          </UploadImage>
        </div>
      </div>
    </>
  );
};

interface AddBoxProps {
  image: string;
  description: string;
  addButtonTitle: string;
  className?: string;
}

function AddBox({
  image,
  addButtonTitle,
  description,
  className,
}: AddBoxProps) {
  return (
    <div
      className={cn(
        "flex flex-col items-center w-full p-8 mx-auto bg-white border border-solid border-bdr-10",
        className
      )}
    >
      <img src={image} alt="" className="w-10 h-10 mb-3" />
      <p className="mb-5 text-center text-gray-60">{description}</p>
      <Button variant="link" className="h-auto p-0 text-sm font-medium">
        + {addButtonTitle}
      </Button>
    </div>
  );
}
