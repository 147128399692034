import React, {
  memo,
  useEffect,
  useState,
  FunctionComponent,
  createContext,
  Children,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useBoard from "@/apis/board";
import { GetAllBoardResponse } from "@/types/boards.type";
import { useQuery } from "@tanstack/react-query";
import { cn } from "@/lib/utils";
import useFilter from "@/hooks/useFilter";
import NewBoard from "@/modal/NewBoard";
import RecentBoardList from "./RecentBoardList";
import PinnedBoardList from "./PinnedBoardList";
import { Skeleton } from "../ui/skeleton";

interface SidebarProps {
  viewMobTopNav?: boolean;
}

const Sidebar: FunctionComponent<SidebarProps> = memo(({ viewMobTopNav }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string>(location.pathname);
  const [searchQuery, setSearchQuery] = useState("");
  const [enabled, setEnabled] = useState(true);
  const [activeMenuItem, setActiveMenuItem] = useState<number | null>(null);

  const { getAllBoard } = useBoard();
  const menuItems = [
    { name: "All", path: "/discover/feed" },
    { name: "Objects", path: "/objects/feed" },
    { name: "Artists", path: "/artist/feed" },
    { name: "Businesses", path: "/business/feed" },
    { name: "Events", path: "/events/feed" },
  ];

  const handleNavigation = (path: string) => {
    setActiveItem(path);
    navigate(path);
  };

  const handleBoards = (boardID: string) => {
    navigate(boardID);
  };

  const imageSources = [
    { id: 0, src: "/starLogoAlt.svg", alt: "Star Logo" },
    { id: 1, src: "/caseAlt.svg", alt: "Case Icon" },
    { id: 2, src: "/eyeAlt.svg", alt: "Eye Icon" },
    { id: 3, src: "/framingAlt.svg", alt: "Framing Icon" },
    { id: 4, src: "/paintAlt.svg", alt: "Paint Icon" },
    { id: 5, src: "/playAlt.svg", alt: "Play Icon" },
    { id: 6, src: "/scholarAlt.svg", alt: "Scholar Icon" },
    { id: 7, src: "/tvAlt.svg", alt: "TV Icon" },
    { id: 8, src: "/starAlt.svg", alt: "Star Alt Icon" },
    { id: 9, src: "/personAlt.svg", alt: "Person Icon" },
    { id: 10, src: "/writingAlt.svg", alt: "Writing Icon" },
    { id: 11, src: "/heart.svg", alt: "Heart Icon" },
  ];

  const { filterOption } = useFilter({ options: { pagination: false } });
  const { data } = useQuery<GetAllBoardResponse>({
    queryKey: ["boards", filterOption],
    queryFn: () =>
      getAllBoard({
        params: filterOption,
      }),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    retry: 3,
  });
  useEffect(() => {
    setActiveItem(location.pathname);
    // Find the index of the menu item based on the current path
    const activeIndex = menuItems.findIndex(
      (item) => item.path === location.pathname,
    );
    setActiveMenuItem(activeIndex);
  }, [location.pathname, menuItems]); // Update on pathname change

  const pinnedBoards = data?.data?.data?.filter((board) => board.pinned) || [];

  return (
    <>
      {/* desktop  */}
      <div className="mq450:hidden mq1000:hidden w-[252px] max-h-screen overflow-y-scroll no-scrollbar bg-white box-border flex flex-col items-start justify-start pt-9 pb-[351px] pr-[22px] pl-12 gap-[31.8px] text-left text-lg text-grey60 font-paragraph-p3 border-r-[1px] border-solid border-gray-20 mq975:hidden mq450:gap-[16px] mq450:pl-5 mq450:box-border mq725:pt-5 mq725:pb-[148px] mq725:box-border mq1025:pt-[23px] mq1025:pb-[228px] mq1025:box-border">
        {menuItems.map((item) => (
          <div
            key={item.path}
            className={cn(
              "w-[156px] flex flex-row items-start justify-start pt-[3px] px-0 pb-1 box-border cursor-pointer text-[#696969] font-normal font-sh5",
              location.pathname == item.path && "font-medium text-gray-900",
            )}
            onClick={() => handleNavigation(item.path)}
          >
            <div className="relative leading-[140%] inline-block min-w-[19px]">
              {item.name}
            </div>
          </div>
        ))}
        <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-gray-20" />
        <div className="w-[156px] flex flex-row items-start justify-start">
          <div className="relative leading-[140%] inline-block min-w-[106px] text-gray-60">
            Liked Objects
          </div>
        </div>
        <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-gray-20" />
        <div className="flex flex-row items-start justify-between w-full">
          <div
            onClick={() => handleNavigation("/boards")}
            className="text-lg font-sh5 font-normal bg-transparent cursor-pointer text-gray-60 relative leading-[140%] inline-block min-w-[92px] "
          >
            Boards
          </div>
          <div className="flex flex-col items-start justify-start pt-[4.5px] px-0 pb-0">
            <NewBoard>
              <img
                className="relative w-4 h-4 overflow-hidden cursor-pointer shrink-0"
                loading="lazy"
                alt=""
                src="/side-panel-icons.svg"
              />
            </NewBoard>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start cursor-pointer gap-[24px]">
          <p className="text-base font-normal text-gray-60 font-sh5">Recent</p>

          <div className="max-h-[300px]  overflow-y-auto flex flex-col gap-6 items-start">
            {data ? (
              <RecentBoardList
                data={data!}
                imageSources={imageSources}
                handleBoards={handleBoards}
              />
            ) : (
              <div className="flex flex-col gap-4">
                {[1, 2, 3, 4, 5, 6].map((_, index) => (
                  <Skeleton
                    key={index}
                    className="lg:w-[120px] h-[25px] w-full"
                  />
                ))}
              </div>
            )}
          </div>

          <p className="text-base font-normal text-gray-60 font-sh5">
            Pinned Boards
          </p>

          <div className="max-h-[300px] w-full overflow-y-auto flex flex-col gap-6 items-start">
            {data ? (
              <PinnedBoardList
                pinnedBoards={pinnedBoards}
                imageSources={imageSources}
                handleBoards={handleBoards}
              />
            ) : (
              <div className="flex flex-col gap-4">
                {[1, 2, 3, 4, 5, 6].map((_, index) => (
                  <Skeleton
                    key={index}
                    className="lg:w-[120px] h-[25px] w-full"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Top Navigation */}
      {viewMobTopNav && (
        <div
          className={cn(
            "hidden bg-[#F2F2F2] border-[#E5E5E5] border-solid border-[1px] fixed top-15 z-10   mq1000:block w-full",
          )}
        >
          <div className="relative  flex flex-row items-center justify-center px-5 pt-4 font-sh5 text-other-cta gap-[24px]">
            {menuItems.map((item, index) => (
              <p
                key={item.name}
                onClick={() => handleNavigation(item.path)}
                className={cn(
                  "relative pb-4 cursor-pointer text-gray-60 border-transparent border-b-[1.5px]",
                )}
              >
                {item.name}
                {/* Conditionally render rectangle under the active item */}
                {item.path === activeItem && (
                  <span className="absolute left-0  bottom-0 w-full h-0.5 bg-black"></span>
                )}
              </p>
            ))}
          </div>
        </div>
      )}
    </>
  );
});
Sidebar.displayName = "Sidebar";
export default Sidebar;
